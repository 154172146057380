'use client';

import ReactDOM from 'react-dom';
import serif from 'src/styles/base/fonts/tiempos-headline-regular.woff2';
import futuraBook from 'src/styles/base/fonts/futura-pt-book-latin.woff2';
import futuraSans from 'src/styles/base/fonts/futura-pt-medium-latin.woff2';

const FONTS = [serif, futuraBook, futuraSans];
const PRECONNECT_ORIGINS = ['https://videos.ctfassets.net', 'https://images.ctfassets.net'];

/**
 * Due to the use of experimental ReactDOM methods that are automatically made
 * available in Next.js but _not_ in Gatsby, this component is Next.js only.
 *
 * https://nextjs.org/docs/app/api-reference/functions/generate-metadata#unsupported-metadata
 */

export const PreloadResources = () => {
  FONTS.forEach(font => {
    ReactDOM.preload(font, { as: 'font', crossOrigin: 'anonymous', type: 'font/woff2' });
  });

  PRECONNECT_ORIGINS.forEach(origin => {
    ReactDOM.preconnect(origin);
  });

  return null;
};
