import {
  sectionExpandAnimation_,
  sectionRevealAnimation_,
  maxWidthNav_,
  maxWidthHighDef_,
} from '../Section.module.scss';

const ANIMATION_TO_CLASS = {
  'expand-in-2d': sectionExpandAnimation_,
  'expand-out-2d': sectionExpandAnimation_,
  reveal: sectionRevealAnimation_,
};

const CONSTRAIN_WIDTH_TO_CLASS = {
  'nav': maxWidthNav_,
  'highDef': maxWidthHighDef_,
};

export { ANIMATION_TO_CLASS, CONSTRAIN_WIDTH_TO_CLASS };
