'use client';

/* eslint-disable react-hooks/rules-of-hooks */

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';
import { DEFAULT_LOCALE_LOWERCASE } from 'src/utils/localization';

export const useHandleContentChange = () => {
  if (process.env.NODE_ENV === 'production') return undefined;

  const { locale } = useTranslationContext();
  const router = useRouter();

  useEffect(() => {
    const handleContentChange = async event => {
      event.preventDefault();

      await fetch('/api/refresh-cms-data', {
        cache: 'no-store',
        method: 'POST',
        body: JSON.stringify({ path: window.location.pathname })
      }).then(() => {
        router.refresh();
      });
    };

    window.addEventListener('stackbitObjectsChanged', handleContentChange);

    return () => {
      window.removeEventListener('stackbitObjectsChanged', handleContentChange);
    };
  }, [router]);

  // sync the page locale with the locale switcher
  useEffect(() => {
    const handleStackbitLocaleChanged = event => {
      let {
        detail: { locale: newLocale },
      } = event;
      // newLocale will be an empty string (though previously was `null`) when locale switcher is in "global" mode
      // so we should navigate back to EN if not already there
      newLocale = newLocale?.toLowerCase() || DEFAULT_LOCALE_LOWERCASE;
      const currentPath = window.location.pathname;
      const newPath = currentPath.replace(locale, newLocale);

      if (newPath !== currentPath) {
        window.location.pathname = newPath;
      }
    };

    window.addEventListener('stackbitLocaleChanged', handleStackbitLocaleChanged);
    return () => {
      window.removeEventListener('stackbitLocaleChanged', handleStackbitLocaleChanged);
    };
  }, [locale]);

  // sync locale switcher with the page locale
  // only works if the switcher is _not_ in "global" mode
  // useEffect(() => {
  //   // need to figure out how to avoid conflicting with the effect above this
  //   // window?.stackbit?.setLocale(locale.replace('ca', 'CA'));
  // }, [locale]);

  return null;
};

export const HandleContentChange = () => {
  useHandleContentChange();

  return null;
};

/* eslint-enable react-hooks/rules-of-hooks */
