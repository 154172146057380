'use client';

import React, { useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { getIsDarkThemeFromBackgroundHexColor } from 'src/utils/colors/color-conversion';
import { colorBackground } from 'src/styles/exports.module.scss';
import {
  section_,
  background_,
  wrapperBackground_,
  sectionVerticalSpacingTight_,
  sectionCollapse_,
  sectionCollapseTop_,
  sectionCollapseBottom_,
  sectionContent_,
  hasShadowAndSqueeze_,
  hasShadow_,
  content_,
  container_,
  squeezeContainer_,
  squeezeContainerWithBorderRadius_,
  squeezeContainerHasShadow_,
  scrollTrigger_,
  firstSection_,
} from './Section.module.scss';
import { SectionSqueezeAnimation } from './SectionSqueezeAnimation/SectionSqueezeAnimation';
import { ANIMATION_TO_CLASS, CONSTRAIN_WIDTH_TO_CLASS } from './helpers/section-class-maps';

const Section = forwardRef(function Section(
  {
    background,
    wrapperBackground,
    colorScheme,
    zIndex,
    className,
    contentClassName,
    forceFullWidth = false,
    sectionIndex,
    verticalSpacing,
    verticalSpacingCollapse = 'none',
    children,
    animation,
    animationBreakpoints,
    animationTarget = 'container',
    shadow = false,
    hasBorderRadiusBottom = false,
    beforeContent,
    afterContent,
    darkTheme = false,
    constrainOuterWidthTo,
    ...props
  },
  sectionRef
) {
  const ref = useRef();
  const contentRef = useRef();
  const { prefersReducedMotion } = useMotionOptOutContext();
  const containerClassName = forceFullWidth ? '' : container_;
  const { style = {}, ...restProps } = props;

  const hasSqueezeOrExpandAnimation = !prefersReducedMotion && animation && (animation.includes('squeeze') || animation.includes('expand'));
  const hasSqueezeOrExpandAnimationTargetingContainer = hasSqueezeOrExpandAnimation && animationTarget === 'container';
  const hasAnimationTargetingContent = animationTarget === 'content';

  // Backgrounds
  const shouldUseWrapperBackground = hasSqueezeOrExpandAnimation || hasBorderRadiusBottom;
  const shouldExtendBackgroundUpward = sectionIndex === 0;
  const shouldUseDarkTheme = darkTheme || getIsDarkThemeFromBackgroundHexColor(background);
  const scrollDetectionId = `section-${sectionIndex}-scroll-bottom`;
  const sectionStyleProps = {
    '--background': background || colorBackground,
    '--section-wrapper-background': wrapperBackground,
    ...(zIndex ? { zIndex } : {}),
    ...style,
  };

  const sectionComponent = (
    <section
      ref={sectionRef}
      className={classNames(
        'Section',
        section_,
        verticalSpacing === 'tight' && sectionVerticalSpacingTight_,
        background_,
        shouldUseWrapperBackground && wrapperBackground_,
        hasSqueezeOrExpandAnimationTargetingContainer && shadow && hasShadowAndSqueeze_,
        !hasSqueezeOrExpandAnimationTargetingContainer && !hasBorderRadiusBottom && shadow && hasShadow_,
        ANIMATION_TO_CLASS[animation],
        CONSTRAIN_WIDTH_TO_CLASS[constrainOuterWidthTo],
        shouldExtendBackgroundUpward && firstSection_,
        className
      )}
      id={`section-${sectionIndex}`}
      style={sectionStyleProps}
      {...restProps}
    >
      {!hasAnimationTargetingContent && beforeContent}
      {hasSqueezeOrExpandAnimationTargetingContainer && (
        <SectionSqueezeAnimation
          ref={ref}
          trigger={`#${scrollDetectionId}`}
          id={`section-squeeze=${sectionIndex}`}
          animationName={animation}
          breakpoints={animationBreakpoints}
        >
          <div
            className={classNames(background_, squeezeContainer_, 'sectionSqueezeAnimationDiv')}
            ref={ref}
          />
        </SectionSqueezeAnimation>
      )}
      {!hasSqueezeOrExpandAnimationTargetingContainer && hasBorderRadiusBottom && (
        <div
          className={classNames(
            background_,
            squeezeContainer_,
            squeezeContainerWithBorderRadius_,
            shadow && squeezeContainerHasShadow_
          )}
          ref={ref}
        />
      )}
      <div
        ref={contentRef}
        className={classNames(
          'SectionContent',
          hasAnimationTargetingContent && background_,
          SPACING_COLLAPSE_TO_CLASS[verticalSpacingCollapse],
          contentClassName,
          shouldUseDarkTheme && 'ws-content-dark-theme',
          content_
        )}
      >
        {hasAnimationTargetingContent && beforeContent}
        <div className={classNames('SectionContainer', containerClassName)}>
          {React.Children.map(children, (child, index) => {
            const isFirstChild = index === 0;
            const isLastChild = index === React.Children.count(children) - 1;
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                sectionIndex,
                subsectionIndex: index,
                isFirstChild,
                isLastChild,
                shouldUseDarkTheme,
                colorScheme,
              });
            }
            return child;
          })}
        </div>
      </div>
      {afterContent}
      <div id={scrollDetectionId} className={scrollTrigger_} />
    </section>
  );

  if (hasAnimationTargetingContent) {
    return (
      <SectionSqueezeAnimation animationName={animation} breakpoints={animationBreakpoints} ref={contentRef}>
        {sectionComponent}
      </SectionSqueezeAnimation>
    );
  }

  return sectionComponent;
});

const SPACING_COLLAPSE_TO_CLASS = {
  none: sectionContent_,
  all: sectionCollapse_,
  top: sectionCollapseTop_,
  bottom: sectionCollapseBottom_,
};

Section.propTypes = {
  background: PropTypes.string,
  wrapperBackground: PropTypes.string,
  colorScheme: PropTypes.shape({}),
  zIndex: PropTypes.number,
  className: PropTypes.string,
  forceFullWidth: PropTypes.bool,
  verticalSpacing: PropTypes.oneOf(['normal', 'tight']),
  verticalSpacingCollapse: PropTypes.oneOf(['none', 'top', 'bottom', 'all']),
  children: PropTypes.node.isRequired,
  sectionIndex: PropTypes.number,
  animation: PropTypes.oneOf([
    'squeeze',
    'squeeze-in-out',
    'expand-in-2d',
    'expand-out-2d',
    'reveal',
  ]),
  /**
   * Which element the squeeze animation will be applied to.
   */
  animationTarget: PropTypes.oneOf(['container', 'content']),
  shadow: PropTypes.bool,
  hasBorderRadiusBottom: PropTypes.bool,
  beforeContent: PropTypes.node,
  afterContent: PropTypes.node,
  breakpoints: PropTypes.arrayOf(PropTypes.string),
  darkTheme: PropTypes.bool,
  /**
   * Instead of infinite width, constrain the outer width of the Section element
   * to one of: a) the main navigation width, b) 1920px
   */
  constrainOuterWidthTo: PropTypes.oneOf(['nav', 'highDef']),
};

Section.displayName = 'Section';

export { Section };
