'use client';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { toFieldPath } from '@stackbit/annotations';
import isString from 'lodash/isString';
import { REFRESH_BREAKPOINTS, classNames } from 'src/utils/css';
import { MARKDOWN_MODES } from 'src/tapestry/core/markdown';
import { WATERFALL_ANIMATION_DELAY } from 'src/components/subsections/FeatureBlock';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { CTALink } from '../CTALink';
import { FluidText } from '../FluidText';
import {
  eyebrow_,
  heading_,
  subheading_,
  cta_,
  wrapper_,
  centered_,
} from './FeatureBlockBody.module.scss';

const renderCTA = (cta, className, animationDelay) => {
  if (cta?.label && cta.href) {
    return (
      <span className={className} style={{ transitionDelay: `${animationDelay}s` }}>
        <CTALink className={cta_} href={cta.href} withArrow>
          {cta.label}
        </CTALink>
      </span>
    );
  }
  if (React.isValidElement(cta)) {
    return (
      <span
        className={classNames(className, cta_)}
        style={{ transitionDelay: `${animationDelay}s` }}
      >
        {cta}
      </span>
    );
  }
  return undefined;
};

export const FeatureBlockBody = ({
  className,
  eyebrow,
  heading = '',
  subheading,
  cta,
  reverseWaterfallAnimation,
  centered = false,
}) => {
  const ref = useRef();
  const [viewRef, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const { prefersReducedMotion } = useMotionOptOutContext();
  const { entryId } = useComponentContext();

  const subheadingDelay = heading && subheading ? 1 : 0;
  const ctaDelay = subheadingDelay + (heading ? 1 : 0);

  const renderHeadingAnnotations = entryId
    ? toFieldPath({ objectId: entryId, fieldPath: 'sectionHeading' })
    : {};
  const renderSubHeadingAnnotations = entryId
    ? toFieldPath({ objectId: entryId, fieldPath: 'sectionBody' })
    : {};

  return (
    <div
      ref={viewRef}
      className={classNames(
        className,
        wrapper_,
        inView && 'waterfall-trigger',
        reverseWaterfallAnimation && !prefersReducedMotion && 'waterfall-transition',
        centered && centered_
      )}
    >
      <div ref={ref}>
        {eyebrow &&
          React.cloneElement(eyebrow, {
            className: classNames(eyebrow?.props?.className, 'waterfall-element', eyebrow_),
            ...renderHeadingAnnotations,
          })}
        {isString(heading) ? (
          <FluidText
            type="h2"
            className={classNames('waterfall-element', heading_)}
            maxBreakpoint={REFRESH_BREAKPOINTS.md}
            {...renderHeadingAnnotations}
          >
            {heading}
          </FluidText>
        ) : (
          heading &&
          React.cloneElement(heading, {
            className: classNames(
              heading?.props?.className,
              classNames('waterfall-element', heading_)
            ),
            ...renderHeadingAnnotations,
          })
        )}
        {subheading && isString(subheading) ? (
          <div
            className={classNames('waterfall-element', subheading_)}
            style={{ transitionDelay: `${subheadingDelay * WATERFALL_ANIMATION_DELAY}s` }}
          >
            <FluidText
              type="markdown"
              markdownProps={{
                mode: MARKDOWN_MODES.textWithHeadingsAndLists
              }}
              {...renderSubHeadingAnnotations}
            >
              {subheading}
            </FluidText>
          </div>
        ) : (
          subheading &&
          React.cloneElement(subheading, {
            className: classNames(
              subheading?.props?.className,
              classNames('waterfall-element', subheading_)
            ),
            style: {
              transitionDelay: `${subheadingDelay * WATERFALL_ANIMATION_DELAY}s`,
              ...(subheading?.props?.style ?? {}),
            },
            ...renderHeadingAnnotations,
          })
        )}
      </div>
      {renderCTA(cta, 'waterfall-element', ctaDelay * WATERFALL_ANIMATION_DELAY)}
    </div>
  );
};

FeatureBlockBody.propTypes = {
  eyebrow: PropTypes.node,
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subheading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  cta: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({ label: PropTypes.string, href: PropTypes.string }),
  ]),
  centered: PropTypes.bool,
  entryId: PropTypes.string,
};
