'use client';

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { AutoGrid } from 'src/components/layout/AutoGrid';
import { RefreshCell } from 'src/components/layout/Grid';
import { asSubsection } from 'src/components/layout/Subsection';
import { FeatureBlockBody } from 'src/components/common/FeatureBlockBody';
import { featureBlockMedia } from './FeatureBlock.module.scss';

export const WATERFALL_ANIMATION_DELAY = 0.12;

// FEATURE BLOCK
const FeatureBlock = asSubsection('FeatureBlock')(
  ({
    reverseOrderDesktop,
    reverseOrderMobile,
    presetOverride,
    children,
    reverseWaterfallAnimation = true,
    className = '',
    ...restProps
  }) => {
    const getPreset = useCallback(() => {
      if (reverseOrderMobile) {
        return !reverseOrderDesktop ? 'twoCellReverseMobile' : 'twoCellReverseAll';
      }
      return !reverseOrderDesktop ? 'twoCell' : 'twoCellReverseDesktop';
    }, [reverseOrderMobile, reverseOrderDesktop]);

    return (
      <div {...restProps} className={className}>
        <AutoGrid preset={presetOverride || getPreset()}>
          {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                reverseWaterfallAnimation,
              });
            }
            return child;
          })}
        </AutoGrid>
      </div>
    );
  }
);

FeatureBlock.propTypes = {
  reverseOrderDesktop: PropTypes.bool,
  reverseOrderMobile: PropTypes.bool,
  reverseWaterfallAnimation: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FeatureBlock.displayName = 'FeatureBlock';

// FEATURE BLOCK COPY
const FeatureBlockCopy = ({ children, reverseWaterfallAnimation, ...props }) => {
  return (
    <RefreshCell {...props}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child) && child.type === FeatureBlockBody) {
          return React.cloneElement(child, {
            reverseWaterfallAnimation,
          });
        }
        return child;
      })}
    </RefreshCell>
  );
};

// FEATURE BLOCK MEDIA (CELL)
const FeatureBlockMedia = ({
  className,
  children,
  // prevent unintentionally passing this prop to children and causing console error
  reverseWaterfallAnimation: _reverseWaterfallAnimation,
  ...props
}) => (
  <RefreshCell {...props}>
    <div className={classNames(featureBlockMedia, className)}>{children}</div>
  </RefreshCell>
);

FeatureBlockMedia.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
};

export { FeatureBlock, FeatureBlockMedia, FeatureBlockCopy };
